<template>
    <v-layout class="flex-column">
        <!-- BODY -->
        <core-body :shown="true" bgcolor="transparent" :loading="body.showLoading" column="x12" :msgshown="body.alert.show"
            :msgtype="body.alert.type" :msg="body.alert.message" :shownd="body.dialog.show" v-on:funcmsgshown="body_alertShow"
            v-on:funcshownd="body_dialogShow">
            <!-- WIDGET -->
            <template slot="content" >
                <v-row class="py-0">
                    <v-col cols="4" style="padding: 0px 0px 0px 0px !important;margin-bottom: 0px !important;">
                        <core-widget bgColor="#414042" styles="" textColor="#414042" textStyles="margin:2px 0px -10px 0px!important;"
                            :bordered="false" :headcount="widget.countUsers" subheadcount=" User"
                            title="Terdaftar" :img="require('@/assets/svg/master/master.svg')"
                            v-on:action="widget_filterStatus()" v-on:action1="widget_filterStatus('1')" v-on:action2="widget_filterStatus('0')"
                            :addInfo="[
                                { text: 'Active', value: `${widget.countActive}`, bgColor: `#45D738`, color: '#45D738', },
                                { text: 'Inactive', value: `${widget.countInactive}`, bgColor: `#C8001D`, color: '#C8001D' }
                        ]" />
                    </v-col>
                    <v-col cols="4" style="padding: 0px 6px 0px 6px !important;margin-bottom: 0px !important;">
                        <core-widget bgColor="#414042" styles="" textColor="#414042" textStyles="margin:2px 0px -10px 0px!important;"
                            :bordered="false" :headcount="widget.countUserSaksi" subheadcount=" Orang"
                            title="Saksi" :img="require('@/assets/svg/user/users.svg')" v-on:action="widget_filterRole('Saksi')" v-on:action1="widget_filterRole('Saksi Mandat')" v-on:action2="widget_filterRole('Saksi Pendamping')"
                            :addInfo="[
                                { text: 'Mandat', value: `${widget.countMandat}`, bgColor: `#45D738`, color: '#45D738', },
                                { text: 'Pendamping', value: `${widget.countPendamping}`, bgColor: `#FF8186`, color: '#FF8186' }
                        ]" />
                    </v-col>
                    <v-col cols="4" style="padding: 0px 0px 0px 0px !important;margin-bottom: 0px !important;">
                        <core-widget bgColor="#FFB727" styles="" textColor="#FFB727" textStyles="margin:2px 0px -10px 0px!important;"
                        v-on:action="widget_filterRole('Korlap')" :bordered="false" :headcount="widget.countKorlap"
                        subheadcount=" Orang" title="Korlap" :img="require('@/assets/svg/user/korlap.svg')" />
                    </v-col>
                </v-row>
            </template>
            <template slot="content">
                <v-flex class="v-toolbar toolbar-fleet-table toolbar-table-asset">
                    <div class="py-4 px-2" style="padding-bottom:0px!important;">
                        <v-row>
                            <!-- SEARCH -->
                            <v-col cols="12" sm="8" class="mb-0">
                                <!-- :newCols="table.search.colDevice" :titleCols="2" classForm="" :multiple="false" -->
                                <core-searchParam 
                                    :datas="table.tblHeader" :selectData="table.search.selected" :v_model="table.search.value" v-on:getSelected="search_searchBy"
                                    v-on:getValue="table_searchValue" />
                            </v-col>
                            <!-- DOWNLOAD -->
                            <v-col cols="12" sm="4" class="mb-0">
                                <div style="text-align:right;">
                                    <v-btn class="btn-export-xl color-danger" title="Download"
                                        @click="form_downloadData('xlsx')"><v-icon>mdi-microsoft-excel</v-icon> Export to excel
                                    </v-btn>
                                    <v-btn class="btn-add-asset mr-0" color="" title="Add User" @click="form_addUser()">
                                        <v-icon>mdi-account-plus</v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </v-flex>
                <!-- TABLE -->
                <core-table-fixed-outer-grid class="px-2 table-fleet-management" :tblHeader="table.tblHeader" backgroundHeader="#C8001D" 
                    :items="table.tblData" id="tbl_exporttable_to_xls" :searchby="table.search.selected" :search="table.search.value" 
                    item-key="iUsersID" :height="'auto'">
                    <!-- CUSTOME COLUMN & VALUE -->
                    <template v-slot:[`item.sRole`]="{ item }">
                        <div :style="`text-align:center;`">
                            <v-chip label small :color="item.sRole=='Saksi Mandat' ?'#45D738' :item.sRole=='Saksi Pendamping' ?'#FF8186' :'#FFB727'">
                                {{ item.sRole }}
                            </v-chip>
                        </div>
                    </template>
                    <template v-slot:[`item.iStatus`]="{ item }">
                        <div :style="`text-align:center;`">
                            <v-chip label small :color="item.iStatus==1 ?'#45D738' :'#C8001D'">
                                {{ item.iStatus==0 ?'Inactive' : 'Active' }}
                            </v-chip>
                        </div>
                    </template>
                    <!-- ACTION ROW -->
                    <template v-slot:[`item.actions`]="{ item }">
                        <div :style="`text-align:center;`">
                            <v-icon @click="table_showDetail(item)" title="Edit" color="#C8001D">mdi-account-edit</v-icon>
                            <v-icon @click="table_setPassword(item)" title="Set Password" color="#C8001D">mdi-form-textbox-password</v-icon>
                        </div>
                    </template>
                </core-table-fixed-outer-grid>
            </template>
        </core-body>
        <!-- POPUP -->
        <v-main>
            <v-layout align-center justify-center style="border-radius:15px 15px 0px 0px">
                <v-dialog persistent v-model="form.detail.show" scrollable width="1200" style="position:absolute;">
                    <Detail v-if="form.detail.show" :modeEdit="form.detail.edited" :data="form.detail.dataDetail"
                        v-on:closePopUp="detail_btnClose()" />
                </v-dialog>
                <v-dialog persistent v-model="form.password.show" scrollable width="800" style="position: absolute">
                    <Password v-if="form.password.show" :modeEdit="form.password.edited" :data="form.password.dataDetail"
                        v-on:closePopUp="password_btnClose()" />
                </v-dialog>
            </v-layout>
        </v-main>
        <!-- FOOTER -->
		<core-footer :shown="true" :bgcolor="`white`" column="x12"></core-footer>
    </v-layout>
</template>

<script>
import { success, error } from '@/consistencychecker/transactionvalidator/index'
import { numberFormat } from "@/consistencychecker/formvalidator/format"
import UsersAPI from '@/services/sa/SA_User'
import Detail from './Detail.vue'
import XLSX from '@/plugins/exportExcel'
import { styleHeaderExcel } from '@/consistencychecker/formvalidator/file'
import Password from './Password.vue'

export default {
    components: { Detail, Password },
    metaInfo() {
        return { title: 'Quick Count' }
    },
    name: 'UsersMain',
    data() {
        return {
            body: {
                showLoading: true,
                alert: { show: false, type: '', message: '', },
                dialog: { show: false, detail: false, },
            },
            widget:{
                countUsers:`0`, countActive:`0`, countInactive:`0`,
                countUserSaksi:`0`, countMandat:`0`, countPendamping:`0`,
                countKorlap:`0`
            },
            form:{
                detail:{
                    show:false, edited: false,
                    dataDetail:{ title:``,
                        iUserID:null, sKtpNo:``, sName:``, sContact:``,
                        sEmail:``, sRole:``, iStatus:1, kecID:0, kelID:0, tpsID:0
                    }
                },
                password:{
                    show:false, edited: false,
                    dataDetail:{ title:``,
                        iUserID:null, sKtpNo:``, sName:``, sContact:``,
                    }
                }
            },
            table:{
                search:{
                    colDevice: window.screen.width >= 600 ? 12 : 6,
                    selected:``, value:``
                },
                tblHeader:this.$store.getters['app/enum'].TH_USERS_MAIN,
                tblData:[]
            }
        }
    },
    computed: {
        console: () => console,
        window: () => window,
        isLoggedIn() {
            return this.$store.getters['auth/isSessionActive']            
        },
    },
    created() {
        this.getData()
    },
    async beforeMount() {
        // get data user
    },
    async mounted() {

    },
    methods: {
        // Handling Default Function
        handleSuccess(data) {
            this.body.alertShow = true
            this.body.alertType = this.$store.getters['app/color']('s')
            this.body.alertMsg = success(data)
            setTimeout(() => { this.body.loading = false }, 1000)
            setTimeout(() => { this.body.alertShow = false }, 3000)
        },
        handleError(err) {
            this.body.alertShow = true
            this.body.alertType = this.$store.getters['app/color']('e')
            this.body.alertMsg = error(err)
            setTimeout(() => { this.body.loading = false }, 1000)
            setTimeout(() => { this.body.alertShow = false }, 3000)
        },
        body_alertShow(){
            this.body.alertShow = false
        },
        body_dialogShow(){
            this.body.dialogShow = false
        },
        search_searchBy(val){
            this.table.search.selected = val
            this.table.search.value = ``
        },
        table_searchValue(val){
            this.table.search.value = val
        },
        // Handling Filter
        widget_filterStatus(val){
            if (val) this.table.search.value = `iStatus:${parseInt(val)}`
            else this.table.search.value = ``
        },
        widget_filterRole(val){
            if (val) this.table.search.value = `sRole:${val}`
            else this.table.search.value = ``
        },
        // Handling Form
        form_addUser(){
            this.form.detail.dataDetail.title = `User Add`
            this.form.detail.dataDetail.iUserID=null
            this.form.detail.dataDetail.sKtpNo=``
            this.form.detail.dataDetail.sName=``
            this.form.detail.dataDetail.sContact=``,
            this.form.detail.dataDetail.sEmail=``
            this.form.detail.dataDetail.sRole=`Korlap`
            this.form.detail.dataDetail.kecID=0
            this.form.detail.dataDetail.kelID=0
            this.form.detail.dataDetail.tpsID=0
            this.form.detail.dataDetail.iStatus=1
            this.form.detail.edited = false
            this.form.detail.show = true
        },
        form_downloadData(){
            const resultHeader = this.table.tblHeader.filter(x=>x.value!='actions').map((item) => item.text);
            const resultValue = this.table.tblHeader.filter(x=>x.value!='actions').map((item) => item.value);
            let result = [...this.table.tblData].map((item,h) => {
                return resultValue.map((field) => {
                    let items = item[field]
                    if(field==='iStatus'){
                        items = item[field]==1?'Active':'Inactive'
                    }
                    return items
                })
            })
            let tgl = new Date();
            let dateNow = `${("0"+tgl.getDate()).substr(-2)}-${("0"+tgl.getMonth()).substr(-2)}-${("0" + tgl.getFullYear()).substr(-2)}`;
            // CONST CONFIG
            const ws = XLSX.utils.json_to_sheet(result);
            const wb = { Sheets: { 'Data User': ws }, SheetNames: ['Data User'] }
            XLSX.utils.sheet_add_aoa(ws, [resultHeader]);
            ws["A1"].s = styleHeaderExcel.h1, ws["B1"].s = styleHeaderExcel.h1, ws["C1"].s = styleHeaderExcel.h1,
            ws["D1"].s = styleHeaderExcel.h1, ws["E1"].s = styleHeaderExcel.h1, ws["F1"].s = styleHeaderExcel.h1
            XLSX.utils.book_append_sheet(wb, ws)
            XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
            XLSX.writeFile(wb, `QuickCount-Users ${dateNow}.xlsx`)
        },
        table_showDetail(item){
            this.form.detail.dataDetail.title = `User Edit`
            this.form.detail.dataDetail.iUserID=item.iUserID
            this.form.detail.dataDetail.sKtpNo=item.sKtpNo
            this.form.detail.dataDetail.sName=item.sName
            this.form.detail.dataDetail.sContact=item.sContact
            this.form.detail.dataDetail.sEmail=item.sEmail
            this.form.detail.dataDetail.sRole=item.sRole
            this.form.detail.dataDetail.iStatus=item.iStatus
            this.form.detail.dataDetail.kecID=item.kecID
            this.form.detail.dataDetail.kelID=item.kelID
            this.form.detail.dataDetail.tpsID=item.tpsID
            this.form.detail.edited = false
            this.form.detail.show = true
        },
        table_setPassword(item){
            this.form.password.dataDetail.title = `Set Password`
            this.form.password.dataDetail.iUserID=item.iUserID
            this.form.password.dataDetail.sKtpNo=item.sKtpNo
            this.form.password.dataDetail.sName=item.sName
            this.form.password.dataDetail.sContact=item.sContact
            this.form.password.edited = true
            this.form.password.show = true
        },
        // Handling PopUp Detail
        detail_btnClose(){
            this.body.showLoading = true
            this.form.detail.show = false
            this.getData()
        },
        password_btnClose(){
            this.form.password.show = false
        },
        // Handling Get & Set Data
        getData() {
            this.body.dataTable = []
            UsersAPI.getDataUsers().then(res => {
                if (!res.status) this.handleError(res)
                this.setTableColumn(res.data)
                this.setValueWidget()
                setTimeout(() => { this.body.showLoading = false }, 1000)
            }).catch(err => {
                this.handleError(err)
                console.log('error -> Users -> getData() :', err);
            })
        },
        setTableColumn(data) {
            this.table.tblData = data
        },
        setValueWidget(){
            this.widget.countUsers=numberFormat(this.table.tblData.length).toString()
            this.widget.countActive=numberFormat(this.table.tblData.filter(x=>x.iStatus==1).length).toString()
            this.widget.countInactive=numberFormat(this.table.tblData.filter(x=>x.iStatus==0).length).toString()
            this.widget.countUserSaksi=numberFormat(this.table.tblData.filter(x=>x.sRole.includes('Saksi')).length).toString()
            this.widget.countMandat=numberFormat(this.table.tblData.filter(x=>x.sRole=='Saksi Mandat').length).toString()
            this.widget.countPendamping=numberFormat(this.table.tblData.filter(x=>x.sRole=='Saksi Pendamping').length).toString()
            this.widget.countKorlap=numberFormat(this.table.tblData.filter(x=>x.sRole=='Korlap').length).toString()
        },
    }
}
</script>
<style>
.v-data-table-header {
  background-color: #C8001D;
  color: white;
}
.btn-outline {
    height: 40px !important;
}
</style>