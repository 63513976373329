<template>
    <v-card class="primary--text" style="overflow-y: hidden;">
      <core-header :shown="true" bgcolor="" :loading="body.form.loading" column="x12">
      <template slot="content">
        <!-- TITLE -->
        <v-flex xs12 md8 class="px-2" style="margin-top:-14px!important;">
            <core-title color="primary" :title="header.title" />
        </v-flex>
        <!-- TOOLBAR -->
        <v-flex xs12 md4 class="px-2" style="margin-top:-22px !important; padding-right: 0px!important">
          <core-toolbar :btnsetting="false" :btnclose="true" :btnchecklist="false" :btnrefresh="false" :btnreset="false" v-on:closePage="detailClose"></core-toolbar>
        </v-flex>
      </template>
      </core-header>
      <core-alert :flagShow="body.alert.shown" v-on:funcflagShow="body.alert.shown=false" :txtType="body.alert.type" :txtMsgAlert="body.alert.msg" />
      <v-card-text style="padding:2px 2px 0px 10px!important;">
        <v-form ref="form" lazy-validation>
          <div class="form-detail-asset">
            <h3>Default Password terdiri dari 4 huruf awalan [Nama] & 4 digit angka terkahir [NIK]</h3>
            <v-divider></v-divider>
            <v-layout form>
              <v-layout wrap align-center form col-line-one>
                <v-col cols="12" sm="2" md="4" class="mb-4">
                  <core-entry-field-nested-label-dyn-type1 required :outlined="true" filled shaped :disabled="modeEdit" type="text" label="NIK" v-model="body.form.sKtpNo" dense />
                </v-col>
                <v-col cols="12" sm="2" md="4" class="mb-4">
                  <core-entry-field-nested-label-dyn-type1 required :outlined="true" filled shaped :disabled="modeEdit" type="text" label="Name" v-model="body.form.sName" dense/>
                </v-col>
                <v-col cols="12" sm="2" md="4" class="mb-4">
                  <core-entry-field-nested-label-dyn-type1 required :outlined="true" filled shaped :disabled="modeEdit" type="text" label="Contact" v-model="body.form.sContact" dense />
                </v-col>
                <v-col cols="12" sm="2" md="12" class="mb-4">
                  <v-text-field
                      v-model="body.form.sPassword"
                      placeholder="Masukan kata sandi anda disini"
                      outlined dense
                      :append-icon="body.form.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="body.form.showPassword ? 'text' : 'password'"
                      @click:append="body.form.showPassword=!body.form.showPassword"
                  ></v-text-field>
                </v-col>
              </v-layout>
            </v-layout>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions class="footer-button-asset">
        <v-spacer></v-spacer>
        <v-btn class="btn-cancel" small @click="detailClose">Cancel</v-btn>
        <v-btn class="btn-submit" small @click="btnSubmit" :disabled="body.form.loading">Submit</v-btn>
      </v-card-actions>
    </v-card>
  </template>
  
  <script>
  // FUNCTION
  import {success, error} from '@/consistencychecker/transactionvalidator/index'
  import {isValidForm} from '@/consistencychecker/formvalidator/index'
  import UsersAPI from '@/services/sa/SA_User'
  
  export default {
    metaInfo () { return { title: 'Quick Count' } },
    name: 'PopUpPassword',
    props: {
      value: {
        type: Boolean,
        default: true
      },
      modeEdit: {
        type: Boolean,
        default: true
      },
      data:{
        type: Object,
        default: {
          title:``,
          iUserID:null, sKtpNo:``, sName:``, sContact:``
        }
      }
    },
    data (){
      return {
        header:{
          title:this.data.title,
        },
        body: {
          form:{
            iUserID:this.data.iUserID, sKtpNo:this.data.sKtpNo, sName:this.data.sName, sContact:this.data.sContact,
            sPassword:`${this.data.sName.substr(0,4)}${this.data.sKtpNo.substr(12,4)}`,showPassword:true,loading:false
          },
          alert:{
            shown: false,
            type: 'success',
            msg: ''
          }
        },
        footer: {
        },
      }
    },
    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        }
      },
    },
    methods: {
      detailClose(){
        this.$emit('closePopUp');
      },
      btnSubmit(){
        let validDataCheck = {
          sPassword: this.body.form.sPassword,
          sBy: this.$store.getters["auth/getSession"].iUserID
        }
        let validEntry = isValidForm(validDataCheck)
        if(validEntry.status===false) {
          this.handleError(`Ditolak. Password tidak valid!`)
        }else{
          validDataCheck.iUserID=this.body.form.iUserID
          UsersAPI.updatePassUsers(validDataCheck, this.body.form.iUserID).then(data=>{ 
            this.handleSuccess(data)
            setTimeout(()=>{this.detailClose();},2000)
          }).catch(err=> {
            this.handleError(err)
          })
        }
      },
      handleSuccess(data){
        this.body.alert.shown = true
        this.body.alert.type = this.$store.getters['app/color']('s')
        this.body.alert.msg = success(data)
        setTimeout(()=>{this.body.alert.shown=false; this.show=false},3000)
      },
      handleError(err){
        this.body.alert.shown = true
        this.body.alert.type = this.$store.getters['app/color']('e')
        this.body.alert.msg = error(err)
        setTimeout(()=>{this.body.alert.shown=false},3000)
      },
    }
  }
  </script>
  <style>
    .form-detail-asset h3 {
      font-size: 18px !important;
    }
    .status-request {
      background-color: #FFB727 !important;
      border-color: #FFB727 !important;
    }
  
    .detail-asset .v-image{
      max-height: 300px;
      cursor: pointer;
      height: 200px;
      width: 520px;
      margin: auto;
      border: 1px solid #dddddd;
      border-radius: 10px !important;
    }
    
    .detail-asset .v-image__image--contain{
      background-size: cover;
    }
  
    .form-detail-asset .form {
      padding: 8px 0 0 8px;
      margin-right: 8px;
    }
  </style>