<template>
    <v-card class="primary--text" style="overflow-y: hidden;">
      <core-header :shown="true" bgcolor="" :loading="body.form.loading" column="x12">
      <template slot="content">
        <!-- TITLE -->
        <v-flex xs12 md8 class="px-2" style="margin-top:-14px!important;">
            <core-title color="primary" :title="header.title" />
        </v-flex>
        <!-- TOOLBAR -->
        <v-flex xs12 md4 class="px-2" style="margin-top:-22px !important; padding-right: 0px!important">
          <core-toolbar :btnsetting="false" :btnclose="true" :btnchecklist="false" :btnrefresh="false" :btnreset="false" v-on:closePage="detailClose"></core-toolbar>
        </v-flex>
      </template>
      </core-header>
      <core-alert :flagShow="body.alert.shown" v-on:funcflagShow="body.alert.shown=false" :txtType="body.alert.type" :txtMsgAlert="body.alert.msg" />
      <v-card-text style="padding:2px 2px 0px 10px!important;">
        <v-form ref="form" lazy-validation>
          <div class="form-detail-asset">
            <h3>Detail Info</h3>
            <v-divider></v-divider>
            <v-layout form>
              <v-layout wrap align-center form col-line-one>
                <v-col cols="12" sm="2" md="4" class="mb-4">
                  <core-entry-field-nested-label-dyn-type1 required :outlined="true" :disabled="modeEdit" type="text" label="NIK" v-model="body.form.sKtpNo" dense />
                </v-col>
                <v-col cols="12" sm="2" md="4" class="mb-4">
                  <core-entry-field-nested-label-dyn-type1 required :outlined="true" :disabled="modeEdit" type="text" label="Name" v-model="body.form.sName" dense/>
                </v-col>
                <v-col cols="12" sm="2" md="4" class="mb-4">
                  <core-entry-field-nested-label-dyn-type1 required :outlined="true" :disabled="modeEdit" type="text" label="Contact" v-model="body.form.sContact" dense />
                </v-col>
                <v-col cols="12" sm="2" md="4" class="mb-4">
                  <core-entry-field-nested-label-dyn-type1 required :outlined="true" :disabled="modeEdit" type="text" label="Email" v-model="body.form.sEmail" dense />
                </v-col>
                <v-col cols="12" sm="2" md="4" class="mb-4">
                  <v-select required :disabled="modeEdit" v-model="body.form.iStatus" outlined dense :items="body.form.listStatus" item-value="value" item-text="text" menu-props="auto" label="Status" hide-details :single-line="false"></v-select>
                </v-col>
                <v-col cols="12" sm="2" md="4" class="mb-4">
                  <v-select required :disabled="modeEdit" v-model="body.form.sRole" outlined dense :items="body.form.listRole" item-value="value" item-text="text" menu-props="auto" label="Role" hide-details :single-line="false"></v-select>
                </v-col>
                <v-col cols="12" sm="2" md="4" class="mb-4" v-if="scope.saksi.includes(body.form.sRole)||scope.korlap.includes(body.form.sRole)">
                  <v-select required :disabled="modeEdit" v-model="body.form.kecID" outlined dense :items="body.form.listKec" item-value="value" item-text="text" menu-props="auto" label="Kecamatan" hide-details :single-line="false"></v-select>
                </v-col>
                <v-col cols="12" sm="2" md="4" class="mb-4" v-if="scope.saksi.includes(body.form.sRole)||scope.korlap.includes(body.form.sRole)">
                  <v-select required :disabled="modeEdit" v-model="body.form.kelID" outlined dense :items="body.form.listKel" item-value="value" item-text="text" menu-props="auto" label="Kelurahan" hide-details :single-line="false"></v-select>
                </v-col>
                <v-col cols="12" sm="2" md="4" class="mb-4" v-if="scope.saksi.includes(body.form.sRole)">
                  <v-select required :disabled="modeEdit" v-model="body.form.tpsID" outlined dense :items="body.form.listTps" item-value="value" item-text="text" menu-props="auto" label="TPS" hide-details :single-line="false"></v-select>
                </v-col>
              </v-layout>
            </v-layout>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions class="footer-button-asset">
        <v-spacer></v-spacer>
        <v-btn class="btn-cancel" small @click="detailClose">Cancel</v-btn>
        <v-btn class="btn-submit" small @click="btnSubmit" :disabled="body.form.loading">Submit</v-btn>
      </v-card-actions>
    </v-card>
  </template>
  
  <script>
  // FUNCTION
  import {success, error} from '@/consistencychecker/transactionvalidator/index'
  import {isValidForm} from '@/consistencychecker/formvalidator/index'
  import UsersAPI from '@/services/sa/SA_User'
  
  export default {
    metaInfo () { return { title: 'Quick Count' } },
    name: 'PopUpUser',
    props: {
      value: {
        type: Boolean,
        default: true
      },
      modeEdit: {
        type: Boolean,
        default: true
      },
      data:{
        type: Object,
        default: {
          title:``,
          iUserID:null, sKtpNo:``, sName:``, sContact:``, kecID:null, kelID:null, tpsID:null,
          sEmail:``, sRole:``, iStatus:1
        }
      }
    },
    created(){
      this.setFilterKec();
    },
    data (){
      return {
        header:{
          title:this.data.title,
        },
        scope:{
          saksi:['Saksi Mandat', 'Saksi Pendamping'],
          korlap:['Korlap'],
        },
        body: {
          form:{
            iUserID:this.data.iUserID, sKtpNo:this.data.sKtpNo, sName:this.data.sName, sContact:this.data.sContact,
            sEmail:this.data.sEmail, sRole:this.data.sRole, iStatus:this.data.iStatus,szStatus:(this.data.iStatus==1?'Active':'Inactive'),
            kecID:null,kelID:null,tpsID:null,
            listRole:[{text:'Korlap',value:'Korlap'},{text:'Saksi Mandat',value:'Saksi Mandat'},{text:'Saksi Pendamping',value:'Saksi Pendamping'}],
            listStatus:[{text:'Active',value:1},{text:'Inactive',value:0}],
            listKec:[],listKel:[],listTps:[],
            loading:false
          },
          alert:{
            shown: false,
            type: 'success',
            msg: ''
          }
        },
        footer: {
        },
      }
    },
    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        }
      },
    },
    watch:{
      'body.form.kecID'(val){
        this.setFilterKel(val)
      },
      'body.form.kelID'(val){
        this.setFilterTps(val)
      }
    },
    methods: {
      setFilterKec(){
        let dataWilayah = localStorage.getItem('dataWilayah')
        if(dataWilayah){
          let dataTempKec = JSON.parse(dataWilayah).map(x=>{ return {value:x.kecID, text:x.kecName} })
          let dataKec = this.groupingData(dataTempKec, 'value', 'text')
          this.body.form.listKec=dataKec
          this.body.form.kecID=this.data.kecID>0 ? this.data.kecID : dataKec[0].value
          this.setFilterKel()
        }
      },
      setFilterKel(val){
        let dataWilayah = localStorage.getItem('dataWilayah')
        if(dataWilayah){
          let refData = val!==undefined ? val : this.body.form.kecID
          let dataTempKel = JSON.parse(dataWilayah).filter(x=>x.kecID==refData).map(x=>{ return {value:x.kelID, text:x.kelName} })
          let dataKel = this.groupingData(dataTempKel, 'value', 'text')
          this.body.form.listKel=dataKel
          this.body.form.kelID=this.data.kelID>0 ? this.data.kelID : dataKel[0].value
          this.setFilterTps()
        }
      },
      setFilterTps(val){
        let dataWilayah = localStorage.getItem('dataWilayah')
        if(dataWilayah){
          let refData = val!==undefined ? val : this.body.form.kelID
          let dataTempTps = JSON.parse(dataWilayah).filter(x=>x.kelID==refData).map(x=>{ return {value:x.tpsID, text:x.tpsName} })
          let dataTps = this.groupingData(dataTempTps, 'value', 'text')
          this.body.form.listTps=dataTps
          this.body.form.tpsID=this.data.tpsID>0 ? this.data.tpsID : dataTps[0].value
        }
      },
      groupingData(data, key, text){
        let dataTemp=[]
        data.reduce((acc, item)=>{
          if (!acc[item[key]]) {
              acc[item[key]] = { value: item[key], text:item[text] };
              dataTemp.push(acc[item[key]])
          }
          return acc;
        }, {})
        return dataTemp
      },
      detailClose(){
        this.$emit('closePopUp');
      },
      btnSubmit(){
        let validDataCheck = {
          sKtpNo: this.body.form.sKtpNo,
          sName: this.body.form.sName,
          sContact: this.body.form.sContact,
          sRole: this.body.form.sRole,
          iStatus: this.body.form.iStatus,
          sBy: this.$store.getters["auth/getSession"].iUserID
        }
        if(this.scope.saksi.includes(this.body.form.sRole)){
          validDataCheck.kecID=this.body.form.kecID
          validDataCheck.kelID=this.body.form.kelID
          validDataCheck.tpsID=this.body.form.tpsID
        }else if(this.scope.korlap.includes(this.body.form.sRole)){
          validDataCheck.kecID=this.body.form.kecID
          validDataCheck.kelID=this.body.form.kelID
        }
        let validEntry = isValidForm(validDataCheck)
        if(validEntry.status===false) {
          this.handleError(`Ditolak. Harap melangkapi data *Required!`)
        }else{
          validDataCheck.iUserID=this.body.form.iUserID
          validDataCheck.sEmail=this.body.form.sEmail
          if(validDataCheck.kecID==null) validDataCheck.kecID=``
          if(validDataCheck.kelID==null) validDataCheck.kelID=``
          if(validDataCheck.tpsID==null) validDataCheck.tpsID=``
          let useAPI = this.body.form.iUserID==null?UsersAPI.createDataUsers :UsersAPI.updateDataUsers
          useAPI(validDataCheck, this.body.form.iUserID).then(data=>{ 
            this.handleSuccess(data)
            setTimeout(()=>{this.detailClose();},2000)
          }).catch(err=> {
            this.handleError(err)
          })
        }
      },
      handleSuccess(data){
        this.body.alert.shown = true
        this.body.alert.type = this.$store.getters['app/color']('s')
        this.body.alert.msg = success(data)
        setTimeout(()=>{this.body.alert.shown=false; this.show=false},3000)
      },
      handleError(err){
        this.body.alert.shown = true
        this.body.alert.type = this.$store.getters['app/color']('e')
        this.body.alert.msg = error(err)
        setTimeout(()=>{this.body.alert.shown=false},3000)
      },
    }
  }
  </script>
  <style>
    .form-detail-asset h3 {
      font-size: 18px !important;
    }
    .status-request {
      background-color: #FFB727 !important;
      border-color: #FFB727 !important;
    }
  
    .detail-asset .v-image{
      max-height: 300px;
      cursor: pointer;
      height: 200px;
      width: 520px;
      margin: auto;
      border: 1px solid #dddddd;
      border-radius: 10px !important;
    }
    
    .detail-asset .v-image__image--contain{
      background-size: cover;
    }
  
    .form-detail-asset .form {
      padding: 8px 0 0 8px;
      margin-right: 8px;
    }
  </style>